import React from "react";

import { graphql } from "gatsby";

import ShopwareStorefront from "../../../../components/shared/Elements/ShopwareStorefront";

const AdblueProductList = (props) => {
    const element = props.data.page.elements.find(
        (item) => item.shopwareMainCategoryId
    );

    return (
        <ShopwareStorefront
            shopwareMainCategoryId={element.shopwareMainCategoryId}
        />
    );
};

export default AdblueProductList;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "adblue" }) {
            elements {
                ... on ElementShopwareStorefront {
                    shopwareMainCategoryId
                }
            }
        }
    }
`;
